@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
  --bg-light: #f0f0f0;
  --text-light: #333;
  --bg-dark: #333;
  --text-dark: #f0f0f0;
  --primary-color: #2c3e50;
  --secondary-color: #ecf0f1;
  --accent-color: #3498db;
}

body {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.light-mode {
  background-color: var(--bg-light);
  color: var(--text-light);
}

.dark-mode {
  background-color: var(--bg-dark);
  color: var(--text-dark);
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  width: auto;
  margin-right: 10px;
}

.logo h1 {
  font-size: 1.5rem;
  margin: 0;
}

nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 0;
}

nav ul li {
  margin: 0 1rem;
}

nav ul li a {
  color: var(--secondary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: var(--accent-color);
}

.header-buttons {
  display: flex;
  align-items: center;
}

.theme-toggle,
.menu-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--secondary-color);
  margin-left: 1rem;
}

.menu-toggle {
  display: none;
}

main {
  flex-grow: 1;
  padding: 2rem;
}

footer {
  text-align: center;
  padding: 1rem;
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.home .bio,
.climbing-achievements,
.rope-access-projects,
.development-projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.home-logo {
  width: 100px;
  height: auto;
  margin-bottom: 1rem;
}

.climb-card,
.project-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.climb-card:hover,
.project-card:hover {
  transform: translateY(-5px);
}

.climb-card h3,
.project-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.image-placeholder {
  width: 100%;
  height: 200px;
  background-color: #ccc;
  background-size: cover;
  background-position: center;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.project-card a {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.project-card a:hover {
  background-color: #34495e;
}

@media (max-width: 768px) {
  .home .bio,
  .climbing-achievements,
  .rope-access-projects,
  .development-projects {
    grid-template-columns: 1fr;
  }

  .desktop-only {
    display: none;
  }

  .menu-toggle {
    display: block;
  }

  header {
    flex-wrap: wrap;
  }

  nav {
    flex-basis: 100%;
    display: none;
  }

  nav.open {
    display: block;
  }

  nav ul {
    flex-direction: column;
  }

  nav ul li {
    margin: 0;
    text-align: center;
  }

  nav ul li a {
    display: block;
    padding: 1rem;
  }

  .header-buttons {
    margin-left: auto;
  }
}